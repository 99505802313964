import { ModulesMappping } from 'core/modulesMap'
import './ProductInfos.scss'
import Component from 'navigation/component/Component'
import ColorLabel from 'components/color-label/ColorLabel'
import { bindMethod } from 'helpers/bind'

type ProductInfosType = {
  refs: {
    variants: HTMLElement
    accordions: HTMLElement[]
    cover:HTMLImageElement,
    productInfosReassurance: HTMLElement[]
  }
  modules: {
    colorLabel: ColorLabel
  }
}

class ProductInfos extends Component<ProductInfosType> {
  getModulesMap (): ModulesMappping {
    return {
      colorLabel: ['.color-label', ColorLabel]

    }
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)

    this.refs.accordions.forEach((accordion) => {
      accordion[method]('click', this.onAccordionClick)
    })
  }

  // onAccordionClick function is here to toggle the active class on the clicked variant
  onAccordionClick (e: Event): void {
    const target = e.target as HTMLElement
    target.classList.toggle('active')
  }

  initialized (): void {
    this.bindRefs()
    this.bindModules()
    super.initialized()
    if (this.refs.cover) {
      const bbox = this.refs.cover?.getBoundingClientRect()
      this.refs.cover.style.setProperty('--height', `${bbox?.height}px`)
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')!
      canvas.width = this.refs.cover.width
      canvas.height = this.refs.cover.height
      ctx.drawImage(this.refs.cover, 0, 0)
      const data = ctx.getImageData(2, 2, 1, 1).data

      const colorString = `rgb(${data[0]}, ${data[1]}, ${data[2]})`
      this.el.style.setProperty('--color-background-ghost', colorString)
    }
  }

  refresh (el: HTMLElement) {
    const clone = new Component<ProductInfosType>(el)
    clone.bindRefs()
    this.refs.variants.innerHTML = clone.refs.variants.innerHTML
  }

  resize (): void {
    super.resize()
    if (this.refs.cover) {
      const bbox = this.refs.cover?.getBoundingClientRect()
      this.refs.cover.style.setProperty('--height', `${bbox?.height}px`)
    }
  }
}

export default ProductInfos

import { isArray, each, map, invokeMap, defer, mapValues } from 'lodash-es'
import { TinyEmitter } from 'tiny-emitter'
import { lastResolvedRoute } from 'core/router'
import store from 'stores/store'
import { IComponentClass, ModuleMapping, ModulesMappping } from 'core/modulesMap'
import { State } from 'helpers/state'
import { Match } from 'navigo'

export const getRouterData = (string: string) => {
  const routerData = string?.split('|') || []
  let path, tagName

  if (routerData.length === 1) {
    if (!path && ~routerData[0].indexOf('http')) path = routerData[0]
    else tagName = routerData[0]
  } else if (routerData.length === 2) {
    path = routerData[1]
    tagName = routerData[0]
  }

  return { path, tagName }
}

export const forceArray = <T>(a: T | T[]) => (isArray(a) ? a : [a])
export const unforceArray = <T>(a: T[]) => (a.length === 0 ? null : a.length === 1 ? a[0] : a)

export type RouterLink = HTMLElement & {
  hasListenerAttached?: boolean;
  store: State<any>;
  storeName: string;
};

export type BaseComponentType = {
  refs?: Record<string, HTMLElement | HTMLElement[]>;
  modules?: Record<string, any>;
  options?: Record<string, any>;
  context?: Record<string, State<any> | Function>;
};

export const extractRefs = (target: HTMLElement) => {
  const parseRefs = (selector: string, forceArray: boolean, refs: BaseComponentType['refs']) => {
    return Array.from(target.querySelectorAll(`*[${selector}]`)).reduce((memo, el) => {
      const keys = el.getAttribute(selector)
      if (keys) {
        keys.split(/[ ,]+/).forEach((key) => {
          if (memo[key] || forceArray) {
            if (!memo[key]) memo[key] = []
            else if (!isArray(memo[key])) memo[key] = [memo[key]]
            memo[key].push(el)
          } else {
            memo[key] = el
          }
        })
      }
      return memo
    }, refs as any) as BaseComponentType['refs']
  }

  let refs = parseRefs('data-ref', false, {} as BaseComponentType['refs'])
  refs = parseRefs('data-refs', true, refs)
  return refs
}

class Component<Type extends BaseComponentType = BaseComponentType> extends TinyEmitter {
  el: HTMLElement
  options: Type['options']
  refs: Type['refs']
  modules: Type['modules']
  binded: boolean = false
  context: Type['context']
  parent: IComponentClass | null = null

  private routerLinks: RouterLink[] = []
  protected flushed: boolean = false

  constructor (el: HTMLElement, options: Type['options'] = {}) {
    super()
    this.el = el
    this.refs = {} as Type['refs']
    this.modules = {} as Type['modules']
    this.options = options || {}
    this.context = this.options.context || {}
    defer(() => this.initialized())
  }

  initialized () {
    this.bindEvents(true)
  }

  /* BINDINGS EVENTS */
  bindEvents (add = true) {}

  /* BINDINGS REFS */
  bindRefs (target?: HTMLElement) {
    if (!target) target = this.el
    this.refs = extractRefs(target) as Type['refs']
  }

  /* BINDINGS MODULES */
  bindModules () {
    if (!this.modules) this.modules = {} as Type['modules']

    const modulesMap = this.getModulesMap()

    if (this.modules) {
      // Clean older
      this.modules = mapValues(this.modules, (modules, key) => {
        return forceArray(modules)?.filter((module: Component) => {
          if (
            module.el.parentElement &&
            document.body.contains(module.el) &&
            !!modulesMap[key]
          ) return true

          module.flush()
          return false
        })
      })
      for (const module in this.modules) {
        if (this.modules[module].length !== undefined && this.modules[module].length === 0)
          delete this.modules[module]
      }
    }

    each(modulesMap, ([selector, Module]: ModuleMapping, key) => {
      if (!Module) return
      const array = map((selector === 'self' ? [this.el] : this.el.querySelectorAll(selector)) as any[], (el) => {
        // if (el.binded && ~el.binded.indexOf(key)) return
        const alreadyExist = !!forceArray(this.modules?.[key])?.find((m: Component) => m?.el === el)
        if (alreadyExist) return
        const m = new Module(el, this.getModuleParams(el, Module))
        m.parent = this
        // if (!el.binded) el.binded = []
        // el.binded.push(key)
        return m
      }).filter(Boolean)

      if (this.modules?.[key]) {
        // const current = forceArray(this.modules?.[key])?.filter((m: Component) => {
        //   if (m.el.parentElement && document.body.contains(m.el)) return true
        //   m.flush()
        //   return false
        // })

        array.unshift(...this.modules?.[key])
      }
      if (array?.length) (this.modules as any)[key] = unforceArray(array)
    })
  }

  getModulesMap (): ModulesMappping {
    return {}
  }

  getModuleParams (el: HTMLElement, componentConstructor: IComponentClass): Record<string, any> {
    return {
      parent: this,
      context: this.context
    }
  }

  /* BINDINGS ROUTERS */
  bindInternalRouter () {
    const types = ['panel', 'menu', 'popin'] as const
    this.routerLinks = []

    types.forEach((type) => {
      const links = [].slice.call(this.el.querySelectorAll(`[data-${type}]`)) as RouterLink[]
      links.forEach((link) => {
        if (link.hasListenerAttached) return
        link.addEventListener('click', this.onLinkClicked)
        link.hasListenerAttached = true
        link.store = store[type]
        link.storeName = type
      })

      this.routerLinks.push(...links)
    })
  }

  unbindInternalRouter () {
    this.routerLinks && this.routerLinks.forEach((link) => link.removeEventListener('click', this.onLinkClicked))
  }

  onLinkClicked = (event: MouseEvent) => {
    const link = event.currentTarget as RouterLink
    if ((event.ctrlKey || event.metaKey) && link.tagName.toLowerCase() === 'a') return false
    let origin = ''
    if (link.storeName === 'panel')
      origin = window.location.origin

    const routerData = getRouterData(`${origin}${link.getAttribute('data-' + link.storeName) as string}`)
    const path = routerData.path || link.getAttribute('href')
    if (path) link.store.set(path.replace((lastResolvedRoute() as Match).url, ''))

    event.preventDefault()
  }

  resize () {
    this.invoke('resize')
  }

  invoke (method: string) {
    each(this.modules, (module) => {
      if (isArray(module)) invokeMap(module, method)
      else if (module) (module as any)[method]?.()
    })
  }

  flush () {
    // (this.el as any).binded = []
    this.flushed = true
    this.bindEvents(false)
    this.unbindInternalRouter()
    this.invoke('flush')
  }
}

export default Component

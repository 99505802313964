import { bindEmitterMethod, bindMethod } from 'helpers/bind'
import Component from 'navigation/component/Component'
import { ModulesMappping } from 'core/modulesMap'
import SelectButton from 'components/select-button/SelectButton'
import { ProductContext } from 'stores/productStore'
import persistentStore from 'stores/persistentStore'
import cart from 'stores/cart'
import { addToWishlist, removeFromWishlist } from 'actions/wishlistActions'
import store from 'stores'
import './QuickbuyBar.scss'
import ProductPopupBar from 'components/product/product-popup-bar/ProductPopupBar'
import config from 'core/config'
import mqStore from 'stores/mqStore'

type QuickbuyBarType = {
  modules: {
    colorSelector: SelectButton,
    variantSelector: SelectButton,
    popupBar: ProductPopupBar
  }
  refs: {
    closeQuickbuy: HTMLButtonElement,
    productPopupButton: HTMLElement,
    productActions : HTMLElement,
    addToCart: HTMLButtonElement,
    wishlistButton: HTMLElement,
  }
  context: ProductContext
}

const activePopins = ['product-pop-up-bar', 'selector']

class QuickbuyBar extends Component<QuickbuyBarType> {
  getModulesMap (): ModulesMappping {
    return {
      popupBar: ['.product-popup-bar', ProductPopupBar],
      colorSelector: ['.quickbuy-bar__color', SelectButton],
      variantSelector: ['.quickbuy-bar__variant', SelectButton]
    }
  }

  initialized (): void {
    this.bindRefs()
    this.bindModules()
    super.initialized()
    this.detectWishtlist()
  }

  bindEvents (add = true) {
    const method = bindMethod(add)
    if (!this.refs.addToCart || !this.context) return

    const emitterMethod = bindEmitterMethod(add)
    this.refs.addToCart?.[method]('click', this.onAddToCart)
    this.refs.wishlistButton?.[method]('click', this.toggleWishlist)
    this.refs.productPopupButton?.[method]('click', this.modules.popupBar.toggle)
    this.refs.closeQuickbuy?.[method]('click', this.close)

    persistentStore.wishlist.toggleListener(add, this.detectWishtlist)
    if (add) this.detectWishtlist()
    store.popin.toggleListener(add, this.toggleActive)
    // Hide filters on click outside
  }

  close = () => {
    store.popin.set(null)
    store.panel.set(null)
  }

  toggleActive = () => {
    this.el.classList.toggle('active', activePopins.includes(store.popin.get()!))
    if (store.popin.get() === 'selector' && mqStore.tabletPortrait.get())
      this.el.style.setProperty('--top', `${(this.modules.variantSelector.el.children[1] as HTMLElement).offsetHeight}px`)
  }

  onAddToCart = async () => {
    this.refs.addToCart.classList.add('loading')
    window.location.href = config.shopUrl + '/cart/' + `${this.el.dataset.variantId}:1`
    // await cart.addToCart({
    //   id: this.el.dataset.variantId!,
    //   ghostSrc: (this.el.children[0] as HTMLElement).dataset.featured,
    //   getCart: false,
    //   redirectToCheckout: true
    // })
    this.refs.addToCart.classList.remove('loading')
  }

  getVariantUrl = () => this.refs.wishlistButton?.getAttribute('data-wishlist')

  toggleWishlist = () => {
    const url = this.getVariantUrl()
    if (url) {
      const current = persistentStore.wishlist.get()
      const included = current.includes(url)
      const firstChild = this.el.firstElementChild as HTMLElement
      if (included) removeFromWishlist(url)
      else addToWishlist(url, firstChild.dataset.name || '', firstChild.dataset.featured || '')
    }
  }

  detectWishtlist = () => {
    const url = this.getVariantUrl()
    const current = persistentStore.wishlist.get()
    if (url)
      this.refs.wishlistButton.classList.toggle('toggle', current.includes(url))
  }

  refresh (el: HTMLElement) {
    const clone = new Component<QuickbuyBarType>(el)
    clone.bindRefs()

    this.bindEvents(false)

    this.refs.productActions.innerHTML = clone.refs.productActions.innerHTML
    this.refs = {} as any

    this.initialized()
  }

  onVariantUpdate = (variant: any) => {
  }
}

export default QuickbuyBar

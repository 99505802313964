import { bindMethod } from 'helpers/bind'
import './ProductCard.scss'
import Component from 'navigation/component/Component'
import anime, { AnimeInstance } from 'animejs'
import { addToWishlist, removeFromWishlist } from 'actions/wishlistActions'
import persistentStore from 'stores/persistentStore'
import { defer } from 'lodash-es'

type ProductCardType = {
  refs: {
    slider: HTMLElement
    tracks: HTMLButtonElement[]
    wishlistButton: HTMLButtonElement[]
    quickbuyButton: HTMLButtonElement
  }
  modules: {}
}

class ProductCard extends Component<ProductCardType> {
  private animation:AnimeInstance | null = null
  private switching:boolean = false // when navigating slider via tracks, to remove scroll-snap
  private count:number = 0 // number of images
  private fraction:number = 0 // fraction of slider to get image width/scroll offset
  private variant = ''
  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()
    this.variant = this.el.dataset.variant || ''
    if (this.refs.slider) {
      this.count = parseInt(this.refs.slider!.dataset!.count!)
      this.fraction = this.refs.slider.scrollWidth / this.count
    }
    if (this.el.dataset.featured)
      new Image().src = this.el.dataset.featured!
  }

  bindEvents (add = true) {
    const method = bindMethod(add)

    if (this.refs.slider) {
      this.refs.slider[method]('scroll', this.onScroll)
      if (this.refs.tracks && this.refs.tracks.length) {
        this.refs.tracks.forEach((track: HTMLElement) => {
          track[method]('click', this.onTrackClick)
        })
      }
    }
    if (this.refs.wishlistButton) {
      this.refs.wishlistButton.forEach(button => button[method]('click', this.toggleWishlist))
      persistentStore.wishlist.toggleListener(add, this.detectWishtlist)
      defer(() => { if (add) this.detectWishtlist() })
    }

    if (this.refs.quickbuyButton)
      this.refs.quickbuyButton[method]('click', this.onQuickBuy)
  }

  onQuickBuy = (event:Event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  onScroll = (e:Event) => {
    if (this.switching) return

    const active = Math.round(this.refs.slider.scrollLeft / this.fraction)
    this.setActiveTrack(active)
  }

  onTrackClick = async (event: Event) => {
    event.preventDefault()
    event.stopPropagation()
    const index = this.refs.tracks.indexOf(event.currentTarget as HTMLButtonElement)
    this.refs.slider.classList.add('switching')
    const options = {
      scrollLeft: this.refs.slider.scrollLeft
    }
    this.switching = true
    if (this.animation) this.animation.pause()
    this.setActiveTrack(index)
    this.animation = anime({
      targets: options,
      scrollLeft: index * this.fraction,
      easing: 'easeOutExpo',
      update: () => {
        this.refs.slider.scrollLeft = options.scrollLeft
      },

      duration: 1000
    })
    await this.animation.finished
    this.refs.slider.classList.remove('switching')
    this.switching = false
  }

  toggleWishlist = (event:Event) => {
    event.preventDefault()
    event.stopPropagation()
    const current = persistentStore.wishlist.get()
    const included = current.includes(this.variant)
    if (included) removeFromWishlist(this.variant)
    else addToWishlist(this.variant, this.el.dataset.name || '', this.el.dataset.featured || '')
  }

  detectWishtlist = () => {
    const current = persistentStore.wishlist.get()
    this.refs.wishlistButton.forEach(button => {
      button.classList.toggle('toggle', current.includes(this.variant))
    })
  }

  setActiveTrack = (active: number) => {
    this.refs.tracks.forEach((track: HTMLElement, index: number) => {
      if (index === active)
        track.classList.add('active')
      else
        track.classList.remove('active')
    })
  }

  resize (): void {
    this.fraction = (this.refs.slider?.scrollWidth || 0) / this.count
  }
}

export default ProductCard

import { defer } from 'lodash-es'
import router, { getRoot } from 'core/router'
import { State } from 'helpers/state'
import { RouterLink } from 'navigation/component/Component'
import store from 'stores'

import MainPageManager from './MainPageManager'
import CustomPageManager from './CustomPageManager'
import { RequestOptions } from './PageManager'

class VirtualPageManager extends CustomPageManager {
  store: State<string | null>

  constructor (store: State<string | null>, ...args: ConstructorParameters<typeof CustomPageManager>) {
    super(...args)
    this.main = false
    this.store = store
  }

  updatePageLinks (el: HTMLElement) {
    const links = Array.from(el.querySelectorAll('[data-navigo]:not([data-navigo="parent"]), .data-navigo')) as RouterLink[]
    links.forEach(link => {
      if (link.hasListenerAttached) return
      link.addEventListener('click', this.onLinkClicked)
      link.hasListenerAttached = true
    })
  }

  // Inspired by navigo
  onLinkClicked = (event: MouseEvent) => {
    const link = event.currentTarget
    if (!link) return

    if ((event.ctrlKey || event.metaKey) && (link as HTMLElement).tagName?.toLowerCase() === 'a') return false
    event.preventDefault()
    event.stopPropagation()
    // event.stopImmediatePropagation()
    const location = router.getLinkPath(link)
    this.navigateTo(location.replace(/\/+$/, '').replace(/^\/+/, '/'))
  }

  initializeRoutes () {
    defer(() => {
      this.store.listenAndStart((page) => {
        if (this.disabled) return
        this.onRouteUpdate(page)
      })

      const defaultRoute = this.container && this.container.getAttribute('default-route')

      if (!this.currentPage && this.extractPage(this.container)) {
        // if (!defaultRoute && getHistory()?.length > 1) defaultRoute = store.path.get()
        this.store.value = defaultRoute || store.path.get()

        if (!this.store.value) return

        return this.initializePage()
      }
      if (!this.store.value && !this.currentPage)
        if (defaultRoute) this.navigateTo(defaultRoute)
    })
  }

  forceRouteUpdate (...args: Parameters<VirtualPageManager['pageLoaded']>) {
    const [pathName] = args
    this.store.value = pathName
    return this.pageLoaded(...args)
  }

  cancelTransition () {
    this.store.set(null)
    this.state.loading = false
  }

  onRouteUpdate (pathName: string | null, requestOptions: RequestOptions = {}) {
    if (!pathName && pathName !== '') {
      if (this.currentPage) {
        this.previousPage = this.currentPage
        this.state.next = undefined
        this.currentPage = undefined
        this.hidePage()
      }
      return false
    }

    return super.onRouteUpdate(pathName, requestOptions)
  }

  removePage () {
    if (!this.previousPage) return
    if (!this.currentPage) this.state.previous = null
    return super.removePage()
  }

  load (pathName: string, options: RequestOptions) {
    if (!~pathName.indexOf(getRoot())) pathName = getRoot() + pathName
    return super.load(pathName, options)
  }

  rewriteRoute (pathName: string, overwrite = true) {
    if (~pathName.indexOf(getRoot())) pathName = pathName.replace(getRoot(), '')

    if (overwrite) {
      this.store.value = pathName
    } else {
      this.disabled = true
      this.store.set(pathName)
      this.disabled = false
    }
  }

  createPage (el, PageClass) {
    const page = MainPageManager.prototype.createPage.call(this, el, PageClass)
    this.updatePageLinks(page.el)
    return page
  }

  navigateTo (pathName: string) {
    this.store.set(pathName)
  }

  refresh () {
    const pathName = this.store.value
    this.store.value = ''
    this.store.set(pathName)
  }

  async pageLoaded (...args: Parameters<MainPageManager['pageLoaded']>) {
    const xhr = args[1]
    const page = xhr.response
    const el = this.extractPageFromXHR(page?.body)

    if (!page || !page.body || !page.body.innerHTML || !el) {
      this.state.loading = false
      this.state.transitioning = false
      this.store.set(null)
      this.emit('empty')
      return false
    }

    return super.pageLoaded(...args)
  }

  flush () {
    this.store.unlisten()
  }
}

export default VirtualPageManager

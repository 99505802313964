import VirtualPageManager from 'navigation/page-manager/VirtualPageManager'
import Component from 'navigation/component/Component'
import PanelPage from 'navigation/pages/PanelPage'
import scroll from 'core/scroll'
import store from 'stores'
import { bindMethod } from 'helpers/bind'
import './Panel.scss'
type PanelType = {
  refs: {
    panelInner: HTMLElement
    panelOverlay: HTMLElement
  }
}

class Panel extends Component<PanelType> {
  declare private pageManager: VirtualPageManager
  initialized (): void {
    this.bindRefs()
    this.bindModules()
    super.initialized()
  }

  bindModules (): void {
    super.bindModules()
    store.panel.listenAndStart(this.onPanelUpdate.bind(this))
    this.pageManager = new VirtualPageManager(store.panel, this.refs.panelInner, '.shopify-section', PanelPage)
    store.routers.get().panel = this.pageManager
  }

  bindRefs (target?: HTMLElement): void {
    super.bindRefs(target)
  }

  bindEvents (add = true) {
    this.pageManager?.on('loading', this.onLoading)
    this.pageManager?.on('loaded', this.onLoaded)
    const method = bindMethod(add)
    this.refs.panelOverlay?.[method]('click', this.onClose)
  }

  onLoading = () => {
    this.el.classList.add('loading')
  }

  onLoaded = () => {
    this.el.classList.remove('loading')
    this.el.classList.add('opened')
  }

  onClose = () => {
    store.panel.set(null)
  }

  resize () {
    this.pageManager?.resize()
    super.resize()
  }

  onPanelUpdate (panel: typeof store.panel['value']) {
    const notEmpty = !!panel || panel === ''
    // enlevé car n'attend pas que le panel soit chargé donc parfois pas d'animation :(
    // vu que la classe est assigné avant que le contenu du panel soit dans le DOM
    // this.el.classList.toggle('opened', notEmpty)
    if (notEmpty) {
      scroll.lock(true)
    } else {
      if (window.location.hash) window.location.hash = ''
      this.el.classList.remove('opened')
      scroll.unlock(true)
    }
  }
}

export default Panel

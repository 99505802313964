import scroll from 'core/scroll'

import Page, { IPage } from './Page'

export default class PanelPage extends Page implements IPage {
  async askPreload (previousPage?:string) {
    this.el.classList.add('appearing')

    await super.askPreload(previousPage)
    await this.resetScroll()
  }

  async resetScroll () {
    if (this.pageManager?.container.scrollTop) await scroll.scrollTo(0, { target: this.pageManager.container, duration: 200 })
  }

  /* SHOW */
  show (previousPage: string) {
    this.resize()
    Page.prototype.show.call(this, previousPage)
    return Promise.resolve()
  }

  transitionComplete () {
    this.el.classList.remove('appearing')
    super.transitionComplete()
  }

  /* HIDE */

  hide (nextPage: string) {
    // this.el.classList.add('disappearing')
    return Promise.resolve()
  }
}

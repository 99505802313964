import config from 'core/config'
import router from 'core/router'
import { query } from 'data/query'
import store from 'stores'
import { cart, CartSection } from 'stores/cart'

type AddToCartType = {id:string, quantity?:number, sections?:CartSection[], getCart?:boolean, toast?:boolean, ghostSrc?:string, redirectToCheckout?:boolean}

export type CartActions = {
  addToCart: (options:AddToCartType) => Promise<void>
  deleteFromCart: (variantId:string) => Promise<void>
  updateQuantity: (variantId:string, quantity:number) => Promise<void>
  updateVariant: (oldVariant:string, newVariant:string, quantity:number) => Promise<void>
}

const createCartAction = (cart: cart) : CartActions => {
  const addToCart = async ({
    id,
    quantity = 1,
    sections = ['main-cart-header'],
    getCart = true,
    toast = true,
    ghostSrc,
    redirectToCheckout = false
  }: AddToCartType) => {
    store.loading.set(true)

    try {
      const result = await query(config.api.cart.add + '.js', {
        body: {
          items: [{
            id,
            quantity
          }],
          sections
        }
      })

      if (toast && !redirectToCheckout) {
        const w = window as any
        const item = result.items[0]
        store.toaster.set(
          {
            image: ghostSrc || item.featured_image.url,
            title: `${item.product_title} ${w.variantStrings.cart.added}`,
            externalLink: {
              href: '/checkout',
              label: w.variantStrings.cart.checkout
            }
          }
        )
      }

      const update = sections.map(section => {
        return {
          [section]: result.sections?.[section] as string
        }
      }).reduce((acc, item) => ({ ...acc, ...item }), {})
      cart.content.set(update)
      if (getCart)
        await get()
      else
        store.loading.set(false)

      if (redirectToCheckout)
        window.location.href = config.shopUrl + '/checkout'

      return result
    } catch (error: any) {
      store.toaster.set({
        title: error.description || error.message || error
      })
      store.loading.set(false)
      cart.loading.set(false)
      throw error
    }
  }
  const deleteFromCart = async (id:string, getCart = false) => {
    try {
      await query(config.api.cart.change + '.js', {
        body: {
          quantity: 0,
          id
        }
      })
      if (getCart)
        await get()
      else
        store.loading.set(false)
    } catch (error:any) {
      store.toaster.set({
        title: error.description || error.message || error
      })
      store.loading.set(false)
      cart.loading.set(false)
    }
  }
  const updateQuantity = async (id:string, quantity:number) => {
    try {
      const result = await query(config.api.cart.change + '.js', {
        body: {
          id,
          quantity,
          sections: 'main-cart-header,main-cart-items,main-cart-footer,cart-sticky-bar'
        }
      })

      const update = {
        'main-cart-header': result.sections?.['main-cart-header'],
        'main-cart-items': result.sections?.['main-cart-items'],
        'main-cart-footer': result.sections?.['main-cart-footer'],
        'cart-sticky-bar': result.sections?.['cart-sticky-bar']
      }
      await get()
      cart.content.set(update)
      cart.loading.set(false)
    } catch (error: any) {
      store.toaster.set({
        title: error.description || error.message || error
      })
      store.loading.set(false)
      cart.loading.set(false)
    }
  }
  const updateVariant = async (oldVariant:string, newVariant:string, quantity:number) => {
    try {
      await deleteFromCart(oldVariant, false)

      await addToCart({
        id: newVariant,
        quantity,
        sections: ['main-cart-header', 'main-cart-items', 'main-cart-footer', 'cart-sticky-bar'],
        getCart: false,
        toast: false,
        ghostSrc: ''

      })

      store.loading.set(false)
      cart.loading.set(false)
    } catch (error: any) {
      store.toaster.set({
        title: error.description || error.message || error
      })
      store.loading.set(false)
      cart.loading.set(false)
    }
  }
  const get = async () => {
    try {
      const result = await query(config.api.cart.get + '.js')
      cart.count.set(result.item_count)
      store.loading.set(false)
      cart.loading.set(false)
    } catch (error: any) {
      store.toaster.set({
        title: error.description || error.message || error
      })
      store.loading.set(false)
      cart.loading.set(false)
    }
  }

  return {
    addToCart,
    deleteFromCart,
    updateQuantity,
    updateVariant
  }
}

export { createCartAction }

import Component from 'navigation/component/Component'
import { bindMethod } from 'helpers/bind'
import store from 'stores'
import './Overlay.scss'
import scroll from 'core/scroll'

type HeaderType = {}

class Overlay extends Component<HeaderType> {
  bindEvents (add = true) {
    const method = bindMethod(add)
    this.el[method]('click', this.onClick)
    store.blurredOverlay.toggleListener(add, this.onStoreUpdate)
    this.onStoreUpdate(store.blurredOverlay.get())
  }

  onClick = (event: Event) => {
    store.menu.set(null)
    store.panel.set(null)
    store.popin.set(null)
    store.filtersOpened.set(false)
    scroll.unlock(true)
  }

  onStoreUpdate = (overlay: boolean) => {
    this.el.classList.toggle('visible', overlay)
    this.el.classList.toggle('force-darken', store.popin.get() === 'newsletter' || store.panel.get() !== null)
  }
}

export default Overlay

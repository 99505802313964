import anime from 'animejs'
import Component from 'navigation/component/Component'
import './ProductGrid.scss'
import { ModulesMappping } from 'core/modulesMap'
import InfiniteCarousel from 'components/infinite-carousel/InfiniteCarousel'
import { CollectionGridTypes } from 'stores/collectionStore'
import store from 'stores'
import router from 'core/router'
import mqStore from 'stores/mqStore'

import ProductCard from '../product-card/ProductCard'

type ProductGridType = {
  modules: {
    productCardCarousels: InfiniteCarousel[]
  }
}
class ProductGrid extends Component<ProductGridType> {
  private io: IntersectionObserver = null!
  private carouselsIo : IntersectionObserver | null = null
  private currentPage = 1
  private pageCount = 1
  private url = new URLSearchParams(window.location.search)
  private lastCard:HTMLElement = null!
  private _view: CollectionGridTypes = 'grid'

  get view () {
    return this._view
  }

  set view (view: CollectionGridTypes) {
    this.el.classList.remove(`product-grid-${this._view}`)
    this.el.classList.add(`product-grid-${view}`)
    this._view = view
    this.bindModules()
  }

  constructor (el: HTMLElement) {
    super(el)
    this.view = store.view.get()
    this.bindRefs()
  }

  getModulesMap (): ModulesMappping {
    if (this._view === 'row') {
      return {
        productCardCarousels: ['.product-card__slider', InfiniteCarousel],
        productCards: ['.product-card', ProductCard]
      }
    }

    return {
      productCards: ['.product-card', ProductCard]
    }
  }

  bindEvents (add = true): void {
    store.view.toggleListener(add, this.onViewChange)
  }

  onViewChange = (view: CollectionGridTypes) => {
    this.view = view
  }

  bindModules (): void {
    super.bindModules()
    if (this.view === 'row') {
      if (this.modules.productCardCarousels) {
        if (this.carouselsIo) this.carouselsIo.disconnect()
        this.carouselsIo = new IntersectionObserver(this.onCarouselIntersection)
        this.modules.productCardCarousels.forEach(carousel => {
          this.carouselsIo?.observe(carousel.el)
        })
      } else {
        if (this.carouselsIo) this.carouselsIo.disconnect()
      }
    }
  }

  onCarouselIntersection = (entries:IntersectionObserverEntry[]) => {
    if (this.view !== 'row') return
    entries.forEach(entry => {
      const carousel = this.modules.productCardCarousels.find(carousel => carousel.el === entry.target)
      if (carousel) carousel.enabled = entry.isIntersecting
    })
  }

  initialized (): void {
    this.bindModules()
    super.initialized()
    this.io = new IntersectionObserver(this.onEndOfPage)
    this.lastCard = this.el.querySelector('.product-card:last-child')!
    if (this.lastCard) this.io.observe(this.lastCard)
    const page = this.url.get('page')
    this.currentPage = page !== null ? +parseInt(page) : 1
    this.pageCount = parseInt(this.el.dataset.pages || '1')
  }

  onEndOfPage = (entries:IntersectionObserverEntry[]) => {
    if (entries[0].intersectionRatio > 0 && this.currentPage < this.pageCount)
      this.loadPage(this.currentPage + 1)
  }

  loadPage = async (page:number) => {
    let url = window.location.href
    if (window.location.search.length > 0)
      url += `&page=${page}`

    else
      url += `?page=${page}`

    const request = await fetch(url)
    const response = await request.text()
    const dom = new DOMParser().parseFromString(response, 'text/html')
    const products = dom.querySelector('.product-grid')!.children

    anime({
      targets: products,
      opacity: [0, 1],
      duration: 600,
      delay: anime.stagger(100),
      easing: 'easeInOutQuad'
    })
    this.el.append(...products)
    this.io.disconnect()
    this.lastCard = this.el.querySelector('.product-card:last-child')!
    this.io.observe(this.lastCard)
    this.currentPage++
    this.bindModules()
    router.updatePageLinks()
    this.emit('load')
  }

  flush (): void {
    this.io.disconnect()
  }
}

export default ProductGrid

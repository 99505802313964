import ProductGrid from 'components/product/product-grid/ProductGrid'
import { ModulesMappping } from 'core/modulesMap'
import { bindEmitterMethod } from 'helpers/bind'
import InternalPage from 'navigation/pages/InternalPage'

type CollectionInnerType = {
  modules:{
    grid: ProductGrid
  }
}

class CollectionInner extends InternalPage<CollectionInnerType> {
  constructor (el:HTMLElement) {
    super(el)
  }

  initialized (): void {
    super.initialized()
  }

  bindModules () {
    super.bindModules()
  }

  bindEvents (add?: boolean): void {
    super.bindEvents(add)
    const emitterMethod = bindEmitterMethod(add!)
    this.modules.grid[emitterMethod]('load', this.onLoad)
  }

  onLoad = () => {
    this.unbindInternalRouter()
    this.bindInternalRouter()
  }

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      grid: ['.product-grid', ProductGrid]
    }
  }
}
export default CollectionInner
